var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"console-container",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"control",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"icon-clear clear-console",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"filter active",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " data-filter=\"all\">All</span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"filter",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " data-filter=\"error\">Error</span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"filter",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " data-filter=\"warn\">Warning</span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"filter",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + " data-filter=\"info\">Info</span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"search-keyword",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span> <span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"icon-search search",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"logs-container",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"top-space",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div><ul "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"fake-logs",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></ul><ul "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"logs",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></ul><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"bottom-space",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"js-input",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"buttons",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"button cancel",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">Cancel</div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"button execute",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + ">Execute</div></div><span "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"icon-arrow-right",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></span> <textarea></textarea></div></div>";
},"useData":true});